import React from 'react';
import './terms-and-conditions-page.scss';

const styles = {
  center: {
    textAlign: 'center',
  },
  aTag: {
    color: 'var(--cov-blue)',
    fontWeight: '600',
  },
};

const TermsAndConditionsCV = () => (
  <React.Fragment>
    <div className='wwa-container' style={{ fontFamily: 'var(--cov-font)' }}>
      <div className='wwa-desc wwa-boxed'>
        <p style={styles.center}>
          <strong>IMPORTANT EXCLUSIONS</strong>
        </p>
        <p>
          <strong>
						THESE TERMS AND CONDITIONS ARE SOLELY FOR THE TRAVEL MEDICINE RESTRICTIONS REPORT
						SERVICE OFFERED BY DOCHQ. FOR ANY OTHER SERVICE OFFERED TO USERS, USER WILL HAVE TO
						ACCEPT DIFFERENT TERMS AND CONDITIONS.
          </strong>
        </p>
        <p>
          <strong>
						THE TRAVEL MEDICINE RESTRICTIONS DATABASE IS INTENDED AS A GUIDE ONLY FOR USERS WISHING
						TO RESEARCH INFORMATION ON THE IMPORTATION OF MEDICINES FOR PERSONAL USE ACROSS
						INTERNATIONAL BORDERS. IT IS NOT A DEFINITIVE STATEMENT ON INDIVIDUAL COUNTRY
						REQUIREMENTS WHICH MAY CHANGE BETWEEN UPDATES OF THIS DATABASE. IN ADDITION, THE
						IMPLEMENTATION AND ENFORCEMENT OF THE COUNTRY REGULATIONS MAY DEVIATE FROM THE STATED
						INFORMATION. DOCHQ CANNOT ACCEPT RESPONSIBILITY FOR ANY INACCURACIES ARISING. WE
						STRONGLY RECOMMEND THAT USERS ALWAYS CONTACT THE NATIONAL COMPETENT AUTHORITY AT LEAST
						TWO MONTHS AHEAD OF THE PLANNED TRIP TO MAKE SURE THE USER HAS ALL THE REQUIRED
						DOCUMENTS READY ON TIME FOR DEPARTURE.
          </strong>
        </p>
      </div>
      <div className='wwa-desc wwa-boxed'>
        <p>
          <strong>
						PLEASE NOTE THAT THESE TERMS AND CONDITIONS COVER ALL USE OF OUR SERVICES AS SET OUT IN
						OUR WEBSITE AND WE RESERVE THE RIGHT TO AMEND, MODIFY AND/OR CHANGE THE SAME FROM TIME
						TO TIME. FOR THE AVOIDANCE OF DOUBT, THE TERMS APPLICABLE TO THE USER SHALL (SAVE AND
						EXCEPT ANY CHANGES IMPOSED BY LAW) BE THOSE TERMS AS VALID AT THE TIME OF ANY RELEVANT
						TRANSACTION.
          </strong>
        </p>
      </div>

      <h2 className='wwa-main-title'>
				Terms and Conditions for the Supply of Services between DocHQ and the User
      </h2>
      <div className=' main-list'>
        <ol className='wwa-desc wwa-list main-list '>
          <li>
            <span className='chapter-title'>Interpretation</span>
            <ol className='wwa-desc wwa-list'>
              <li>
								Definitions. In these Conditions, the following definitions apply:
                <p>
                  <strong>"Conditions"</strong> these terms and conditions as amended from time to
									time by DocHQ.
                </p>
                <p>
                  <strong>"Contract"</strong> the contract between the User and DocHQ for the supply
									of a Travel Health Consultation Booking.
                </p>
                <p>
                  <strong>"User"</strong> anyone over the age of 18 years of age.
                </p>
                <p>
                  <strong>"Intellectual Property Rights"</strong> all patents, rights to inventions,
									utility models, copyright and related rights, trade marks, service marks, trade,
									business and domain names, rights in trade dress or get-up, rights in goodwill or
									to sue for passing off, unfair competition rights, rights in designs, rights in
									computer software, database right, topography rights, moral rights, rights in
									confidential information (including know-how and trade secrets) and any other
									intellectual property rights, in each case whether registered or unregistered and
									including all applications for and renewals or extensions of such rights, and all
									similar or equivalent rights or forms of protection in any part of the world.
                </p>
                <p>
                  <strong>"DOCHQ"</strong> DOCHQ Limited, business address Wessex House, Upper
									Market Street, Eastleigh, SO50 9FD ,registered address Teme House Whittington
									Road, Whittington, Worcester, United Kingdom, WR5 2RY (company number 11589439), a
									100% subsidiary of HEALTHHQ GROUP LTD (company number 11186392). DocHQ Ltd
                </p>
                <p>
                  <strong>"Report Summary" </strong> a Travel medicine-restricted report conducted
									through the DOCHQ online process for the User’s trip.
                </p>
                <p>
                  <strong>"Website" </strong>shall mean the websites dochq.co.uk, DocHQ App, and any
									iFrames or APIs developed in relation to health advice created by or on behalf of
									DocHQ.
                </p>
              </li>

              <li>
								Construction. In these Conditions, the following rules apply:
                <ol>
                  <li>
										a person includes a natural person,corporate or unincorporated body (whether or
										not having separate legal personality);
                  </li>
                  <li>
										a reference to a party includes its personal representatives, successors or
										permitted assigns;
                  </li>
                  <li>
										a reference to a statue or statutory provision is a reference to such statute or
										statutory provision as amended or re-enacted. A reference to a statute or
										statutory provision includes any subordinate legislation made under that statue
										or statutory provision, as amended or re-enacted;
                  </li>
                  <li>a reference to writing or written includes faxes and e-mails.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>Basis of Contract between DocHQ and the User</span>
            <ol className='wwa-desc wwa-list'>
              <li>
								The Contract constitutes the entire agreement between DocHQ and the User and any
								variation to these conditions shall have no effect unless expressly agreed in
								writing by DocHQ.
              </li>
              <li>
								These Conditions apply to the Contract to the exclusion of any other terms that the
								User seeks to impose or incorporate, or which are implied by trade,custom, practice
								or course of dealing.
              </li>
              <li>
								The User accepting these terms and conditions consents to DocHQ using the User's
								personal/sensitive data in accordance with DocHQ's privacy policy.
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>Permissions</span>
            <p>
							The information in this Website is intended only for purposes of general information.
							You are permitted to use this website for your own personal use. Please take note that
							you may not be the only person using the website. If you share access to this website
							with anyone, that person must also agree to the Terms of Use.
            </p>
          </li>
          <li>
            <span className='chapter-title'>Supply of services</span>
            <ol className='wwa-desc wwa-list'>
              <li>
								DocHQ agrees to provide to the User access to Travel Medicine Restrictions Report
								from the Website
              </li>
              <li>
								DocHQ will use its reasonable endeavours to ensure that the information provided in
								the Report Summary is accurate at the time of its creation. The information used to
								provide the Report summary is also reliant upon the accuracy of the data provided by
								the User.
              </li>
              <li>
								Once the User has completed the Travel Medicine Restrictions search fields, the User
								can view the report summary online or/and click on Send Report to send an email to
								the User containing the report summary link.
              </li>
              <li>
								The Report summary does not take into account any other User information not
								detailed in the search fields.
              </li>
              <li>
								The information shared in the Report Summary does not constitute legal advice and is
								subject to change in accordance with the latest laws and regulations stipulated by
								the Country of destinations government. We advise contacting the destinations
								Government National Competent Authority for the latest information before your
								journey.
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>Nature and use of the services</span>
            <ol className='wwa-desc wwa-list'>
              <li>
								DocHQ’s services are not intended to be used as the sole basis for any business
								decision, nor to relieve the User of its obligation to comply with its own
								obligations under Applicable Law. DocHQ Data is based upon data which is provided by
								third parties, the accuracy and/or completeness of which it would not be possible
								and/or economically viable for DocHQ to guarantee. DocHQ’s services also involve
								models and techniques based on statistical analysis, probability and predictive
								behaviour. The User acknowledges that it is prudent to use, and it is responsible
								for using, the Services as one of a number of factors in its decision-making
								process, and for determining those other factors. Therefore, DocHQ will be liable if
								it fails to comply with its obligation under Clause 4.1 but DocHQ is not able to
								accept any other liability for:
                <ol>
                  <li>
										any inaccuracy, incompleteness or other error in the DocHQ Data which arises as
										a result of data provided to DocHQ by the User or any third party; or
                  </li>
                  <li>
										any failure of the Services to achieve any particular result for the User or any
										Permitted User.
                  </li>
                </ol>
              </li>
              <li>
								The User agrees that it will:
                <ol>
                  <li>
										use the Services, and/or DocHQ Materials provided under this Agreement, for the
										Permitted Purpose only and in accordance with any Documentation;
                  </li>
                  <li>
										not sell, transfer, sub-license, distribute, commercially exploit or otherwise
										make available to, or use for the benefit of, any third party any of the
										Services, and/or DocHQ Materials provided under this Agreement, except as
										specifically permitted by this Agreement;
                  </li>
                  <li>
										not (and will not allow any third party to) adapt, alter, modify, reverse
										engineer, de-compile or otherwise interfere with any DocHQ Materials provided
										under this Agreement without the prior written consent of DocHQ or as otherwise
										permitted by law; and only take such copies of the DocHQ Materials as are
										reasonably required for the use of the DocHQ Materials in accordance with this
										Agreement.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>User's Obligations</span>
            <ol className='wwa-desc wwa-list'>
						The User warrants that:
              <li>
								any information the User provides in completing the Travel Medicine Restrictions
								search fields and in order to create the Report Summary are fully complete and
								accurate;
              </li>
              <li>
								the User will provide DocHQ with such information and materials as DocHQ may
								reasonably require in order to supply the Report Summary.
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>Complaints</span>
            <ol className='wwa-desc wwa-list'>
              <li>
								Any complaints about the services should be addressed to: DocHQ, Wessex House, Upper
								Market Street, Eastleigh, Hampshire, SO50 9FD
              </li>
              <li>
								An acknowledgment of the complaint will be provided to the User within 2 days of
								receipt (unless a full reply can be sent within 5 working days), however we aim to
								acknowledge within 24 hours. A full response should normally be made back to the
								User within 20 working days of receipt of the complaint. Where an investigation is
								still in progress, a letter explaining the reason for the delay will be sent to the
								User and a full response made within 5 working days of completion of the
								investigation.
              </li>
              <li>
								The DocHQ complaints policy applies to complaints against all personnel (both
								clinical and non-clinical), including those clinicians with practising privileges,
								as well as to those against the organisation. In addition, the organisation has a
								documented practising privileges agreement with independent practitioners which
								stipulates that handling complaints is a condition of the granting and continuation
								of privileges. The Medical Advisory Committee (MAC) together with the Clinical
								Director regularly review clinical complaints and advise accordingly on any clinical
								practice issues arising.
              </li>
              <li>
								Depending on the content of the complaint, an appropriate senior manager will review
								the complaint and either confirm the findings and actions taken or offer to
								implement an alternative resolution via DocHQ’s Internal Appeal (including upholding
								the original complaint).
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>Intellectual Property Rights</span>
            <ol className='wwa-desc wwa-list'>
              <li>
								All Intellectual Property Rights in or arising out of or in connection with the
								Travel Medicine Restrictions search shall be owned by DocHQ.
              </li>
              <li>
								The User acknowledges that, in respect of any third party Intellectual Property
								Rights, the User's use of any such Intellectual Property Rights is conditional on
								DocHQ obtaining a written licence from the relevant licensor on such terms as will
								entitle DocHQ to license such rights to the User.
              </li>
              <li>
								The User agrees and warrants that it shall only utilise the Report Summary for its
								intended purpose.
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>Disclaimer</span>
            <ol className='wwa-desc wwa-list'>
              <li>
								The Travel Medicine Restrictions database is intended as a guide only for Users
								wishing to research information on the importation of medicines for personal use
								across international borders. It is not a definitive statement on individual country
								requirements which may change between updates of this database. In addition, the
								implementation and enforcement of the country regulations may deviate from the
								stated information. DocHQ cannot accept responsibility for any inaccuracies arising.
								We strongly recommend that Users always contact the National Competent Authority at
								least two months ahead of the planned trip to make sure the user has all the
								required documents ready on time for departure.
              </li>
              <li>
								All information and materials in this Website including any software program (the
								"Contents") are provided "as is " without warranties of any kind. To the fullest
								extent permitted by law, DocHQ does not warrant and hereby disclaims all express,
								implied and statutory warranties of any kind to you or any third party whether
								arising from usage, custom, trade or by operation of law or otherwise, including but
								not limited to the following:
                <ol>
                  <li>
										any representations or warranties as to the accuracy, correctness, reliability,
										timeliness, non-infringement, title, merchantability or fitness for any
										particular purpose of the Contents; and
                  </li>
                  <li>
										any representations or warranties that the Contents or any functions available
										on this Website or features associated therewith will be accessible,
										uninterrupted or error-free or that defects will be corrected or that this
										Website and the server is and will be free of all viruses and/or other harmful
										elements.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>Limitation of Liability</span>
            <ol className='wwa-desc wwa-list'>
              <li>
								Nothing in these Conditions shall limit or exclude DocHQ's liability for:
                <ol>
                  <li>
										death or personal injury caused by its negligence, or the negligence of its
										employees, agents or subcontractors;
                  </li>
                  <li>fraud or fraudulent misrepresentation; or</li>
                  <li>
										breach of the terms implied by section 2 of the Supply of Goods and Services Act
										1982 (title and quiet possesions).
                  </li>
                </ol>
              </li>
              <li>
								Subject to clause 10.1:
                <ol>
                  <li>
										DocHQ shall not be liable to the User, whether in contract, tort (including
										negligence), breach of statutory duty, or otherwise, for any loss of profit, or
										any indirect or consequential loss arising under or in connection with the
										contract.
                  </li>
                </ol>
              </li>
              <li>
								Except as set out in these Conditions, all warranties, conditions and other terms
								implied by statute or common law are, to the fullest extent permitted by law,
								excluded from the Contract.
              </li>
            </ol>
          </li>
          <li>
            <span className='chapter-title'>Termination</span>
            <p>
							Without limiting its other rights or remedies, DocHQ may terminate the Contract with
							immediate effect by giving written notice to the User if the User commits a material
							breach of the Contract and misuses the information provided by DocHQ.
            </p>
          </li>
          <li>
            <span className='chapter-title'>No Partnership</span>
            <p>
							Nothing in the Contract is intended to, or shall be deemed to, constitute a
							partnership or joint venture of any kind between any of the parties, nor constitute
							any party the agent of another party for any purpose. No party shall have authority to
							act as agent for, or to bind, the other party in any way.
            </p>
          </li>
          <li>
            <span className='chapter-title'>Third Parties</span>
            <p>
							A person who is not a party to the Contract shall not have any rights under or in
							connection with it.
            </p>
          </li>
          <li>
            <span className='chapter-title'>Variation</span>
            <p>
							Except as set out in these Conditions, any variation, including the introduction of
							any additional terms and conditions, to the Contract, shall only be binding when
							agreed in writing and signed by DocHQ.
            </p>
          </li>
          <li>
            <span className='chapter-title'>Governing Law and Jurisdiction</span>
            <p>
							This Contract, and any dispute or claim arising out of or in connection with it or its
							subject matter or formation (including non-contractual disputes or claims), shall be
							governed by, and construed in accordance with English law.
            </p>
          </li>
        </ol>
      </div>
    </div>
  </React.Fragment>
);

export default TermsAndConditionsCV;
