import React, { Component } from 'react';
import { Grid, TextField, Modal } from '@material-ui/core';
import { Link } from 'gatsby';
import LocationAndDestination from '../../components/LocationAndDestination';
import DrugTable from '../../components/DrugTable';
import DrugTableMobile from '../../components/DrugTableMobile';
import RestrictionTable from '../../components/RestrictionTable';
import CountryAndDrugList from '../../components/CountryAndDrugList';
import NCAComponent from '../../components/NCAComponent';
import TopTips from '../../components/TopTips';
import Layout from '../../components/Layout';
import allSvc from '../../services/allServices';
import StepComponent from '../../components/StepComponent';
import '../../assets/css/covermore.scss';
import CovermoreArrow from '../../components/CovermoreArrow';
import Documents from '../../components/Documents';
import MaterialCheckbox from '../../components/MaterialCheckbox/MaterialCheckbox';
import NoInfo from '../../components/NoInfo';
import ReportErrorModal from '../../components/ErrorModal/ReportErrorModal';
import DocModal from '../../components/DocModal/DocModal';
import PrivacyPolicyCV from '../../components/PrivacyPolicy/PrivacyPolicyCV';
import TermsAndConditionsCV from '../../components/TermsAndConditions/TermsAndConditionsCV';
import reportHelpers from '../../helpers/reportHelpers';
import UnmatchedDrugsList from '../../components/UnmatchedDrugsList';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useConfig from '../../helpers/hooks/useConfig';

// Props required from previous page
/**
 * reportId
 * destinationId - otherwise known as name in this case
 */
const styles = {
  margin: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  sidebarContainer: {
    marginTop: '30px',
  },
  termsButton: {
    backgroundColor: 'transparent',
    color: 'var(--cov-blue)',
    borderStyle: 'none',
    fontWeight: 'bold',
    padding: '0px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};
export default class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      startedLoading: new Date(),
      isTermsVisible: false,
      isPrivacyVisible: false,
      maxWidth: 1900,
    };

    // Function bindings
    this.getReport = getReport.bind(this);
    this.initialiseStateData = initialiseStateData.bind(this);
    this.sendEmail = sendEmail.bind(this);
    this.handleError = handleError.bind(this);
    this.handleResize = handleResize.bind(this);

    async function getReport(jsonAPI, reportId) {
      // Axios get using reportId
      return await allSvc
        .getReport(jsonAPI, reportId, this.state.company)
        .then(resp => {
          if (resp.success && resp.data) {
            return resp.data;
          } else {
            return { error: 'Error fetching report' };
          }
        })
        .catch(err => ({ error: 'Error fetching report' }));
    }

    function initialiseStateData(data) {
      const dataForState = {};
      // Matched drugs
      if (data.matched_drugs) {
        dataForState.matched_drugs = data.matched_drugs;
        dataForState.hasRestrictions = reportHelpers.hasRestrictions(dataForState.matched_drugs);
      }
      // Unmatched drugs
      if (data.unmatched_drugs) dataForState.unmatched_drugs = data.unmatched_drugs;
      // Country Names
      if (data.from_country && data.from_country.name)
        dataForState.locationName = data.from_country.name;
      if (data.to_country && data.to_country.name)
        dataForState.destinationName = data.to_country.name;
      // NCA data
      if (data.to_country && data.to_country.nca_address)
        dataForState.nca_address = data.to_country.nca_address;
      if (data.to_country && data.to_country.recommendations)
        dataForState.recommendations = data.to_country.recommendations;
      if (data.to_country && data.to_country.documents)
        dataForState.documents = data.to_country.documents;
      if (data.to_country && data.to_country.restrictions)
        dataForState.restrictions = data.to_country.restrictions;
      if (
        data.to_country &&
				data.to_country.vaccine_information &&
				data.to_country.vaccine_information.length !== 0
      )
        dataForState.vaccinesRequired = true;
      dataForState.isLoading = false;
      const renderTime = this.state.startedLoading.getTime() + 500;

      //email passed in url
      if (data.urlEmail) dataForState.urlEmail = data.urlEmail;
      if (data.email) dataForState.urlEmail = data.email;

      if (renderTime > new Date().getTime()) {
        setTimeout(() => {
          this.setState(dataForState);
        }, renderTime - new Date().getTime());
      } else {
        this.setState(dataForState);
      }
    }

    function sendEmail() {
      if (this.state.terms_accept && reportHelpers.validEmail(this.state.email)) {
        allSvc
          .emailReport(this.state.config.activeGateway, this.state.reportId, {
            email: this.state.email,
            terms_accept: this.state.terms_accept,
            further_information_accept: this.state.further_information_accept,
          }, this.state.company)
          .then(response => {
            if (response.success) {
              this.setState({ displayEmailSent: true, emailPressed: false });
            } else {
              //TODO handle error
            }
          })
          .catch(err => {
            this.setState({ emailPressed: false });
          });
      }
    }
    function handleError() {
      this.setState({ reportError: true, isLoading: false });
    }
    function handleResize() {
      if (typeof window !== 'undefined') {
        window.onresize = this.setState({ maxWidth: window.innerWidth });
      }
    }
  }

  // Before component mounts, check what data is present
  componentWillMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleResize);
    }
    let dataForState;
    // Passed through by navigator
    const { config } = useConfig();
    this.setState({ config });
    if (reportHelpers.hasMinimumData(this.props)) {
      // Has minimum data
      this.getReport(config.activeGateway, this.props.reportId)
        .then(data => {
          if (reportHelpers.reportDataIsValidEnough(data)) {
            dataForState = data;
            this.initialiseStateData(dataForState);
          } else {
            // Data is invalid
            this.handleError();
          }
        }) //TODO handle error
        .catch(err => this.handleError());
    } else {
      // Navigated via email or has no data passed from router
      const isClient = typeof window !== 'undefined';
      if (isClient) {
        const urlParams =
					typeof window === 'undefined' ? null : reportHelpers.getUrlParams(window.location.href);
        if (urlParams && typeof urlParams.error === 'undefined') {
          // Has URL Params
          if (reportHelpers.hasMinimumData(urlParams)) {
            this.setState({ reportId: urlParams.reportId });
            this.setState({ urlEmail: urlParams.email });
            this.setState({ company: urlParams.company });
            // Get all data remaining
            this.getReport(config.activeGateway, urlParams.reportId)
              .then(data => {
                if (reportHelpers.reportDataIsValidEnough(data)) {
                  dataForState = data;
                  this.initialiseStateData(dataForState);
                } else {
                  // Data is invalid
                  this.handleError();
                }
              }) //TODO handle error
              .catch(err => {
                console.log(err);
                this.handleError();
              });
          } else {
            // We've got something in the URL params but it isn't enough to do what we need and nothing from the navigator, we are in trouble.
            this.handleError();
          }
        } else {
          // We've got nothing in the URL params and nothing from the navigator, we are in trouble.
          this.handleError();
        }
      }
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  render() {
    if (
      typeof window !== 'undefined' &&
			this.state.maxWidth === 1900 &&
			window.innerWidth !== this.state.maxWidth
    ) {
      this.setState({ maxWidth: window.innerWidth });
    }
    return (
      <Layout covermore={true}>
        {this.state.isLoading ? (
          <Modal open style={{ backgroundColor: 'var(--doc-light-grey)' }}>
            <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                backgroundColor: '#ffffff',
                padding: '20px',
                borderRadius: '5px',
                minWidth: '250px',
                minHeight: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LoadingSpinner covermore />
            </div>
          </Modal>
        ) : (
          <React.Fragment>
            {this.state.reportError && (
              <ReportErrorModal covermore reportId={this.state.reportId} />
            )}
            <Grid
              container
              direction='column'
              justify='center'
              alignItems='stretch'
              className='covermore'
              style={{ color: '#666666' }}
            >
              <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                <Grid item xs={12} sm={8}>
                  {/* <ReportHeader email={this.state.email} /> */}
                  <LocationAndDestination
                    locationName={this.state.locationName}
                    destinationName={this.state.destinationName}
                  />
                  <React.Fragment>
                    {((typeof this.state.matched_drugs !== 'undefined' && this.state.matched_drugs.length > 0) ||
											(typeof this.state.unmatched_drugs !== 'undefined' &&
												this.state.unmatched_drugs.length > 0)) &&
											(this.state.maxWidth > 600 ? (
											  <DrugTable
											    drugs={this.state.matched_drugs}
											    unmatchedDrugs={this.state.unmatched_drugs}
											  />
											) : (
											  <DrugTableMobile
											    drugs={this.state.matched_drugs}
											    unmatchedDrugs={this.state.unmatched_drugs}
											  />
											))}
                    {typeof this.state.unmatched_drugs !== 'undefined' &&
											this.state.unmatched_drugs.length > 0 && (
                      <UnmatchedDrugsList drugs={this.state.unmatched_drugs} />
                    )}
                    {typeof this.state.restrictions !== 'undefined' &&
											(this.state.restrictions.narcotic || this.state.restrictions.psycotropic) &&
											this.state.documents &&
											this.state.hasRestrictions &&
											typeof this.state.documents.length !== 'undefined' &&
											this.state.documents.length > 0 && (
                      <RestrictionTable restrictions={this.state.restrictions} covermore={true} />
                    )}
                    {typeof this.state.matched_drugs !== 'undefined' && this.state.matched_drugs.length > 0 && (
                      <CountryAndDrugList
                        drugs={this.state.matched_drugs}
                        destinationName={this.state.destinationName}
                        documents={this.state.documents}
                        covermore={true}
                      />
                    )}
                  </React.Fragment>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={4}
                  direction='column'
                  alignItems='center'
                  justify='center'
                  style={styles.sidebarContainer}
                >
                  {/* <MalariaMedicationAd /> product isn't currently ready for advertisement*/}
                </Grid>
              </Grid>
              <Grid container direction='row' justify='center' alignItems='flex-start'>
                <Grid item xs={12} sm={8}>
                  {this.state.documents &&
										this.state.hasRestrictions &&
										typeof this.state.documents.length !== 'undefined' &&
										this.state.documents.length > 0 && (
                    <div>
                      <p style={{ fontWeight: 'bold' }}>
													Follow the steps below to travel safely and legally with your medicine:
                      </p>
                      <Documents
                        documents={this.state.documents}
                        destinationName={this.state.destinationName}
                        nca_address={this.state.nca_address}
                        covermore
                      />
                    </div>
                  )}
                  {(typeof this.state.documents === 'undefined' ||
										this.state.documents.length === 0) && (
                    <NoInfo destinationName={this.state.destinationName} />
                  )}
                  <div style={styles.margin}>
                    {this.state.nca_address && (
                      <NCAComponent nca_address={this.state.nca_address} title={true} covermore />
                    )}
                  </div>
                  <div>
                    <Link to={'/covermore'} style={{ color: 'var(--cov-orange)' }}>
											New search
                    </Link>
                  </div>

                  {this.state.recommendations && <TopTips tips={this.state.recommendations} />}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={4}
                  direction='column'
                  alignItems='center'
                  justify='center'
                  style={styles.sidebarContainer}
                >
                  {/* // Travel insurance advertisement isn't ready yet
              <TravelInsuranceAd /> */}
                </Grid>
              </Grid>
            </Grid>
            {/* hide the part below when user comes from report email */}
            {(typeof this.state.urlEmail === 'undefined' || this.state.urlEmail === '') && (
              <Grid className='covermore'>
                <StepComponent stepNumber='4' stepMessage='Email your report'>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction='column'
                      justify='center'
                      alignItems='stretch'
                      style={{ marginTop: '10px', marginBottom: '20px' }}
                    >
                      <TextField
                        error={!reportHelpers.emailValidation(this.state.email)}
                        inputProps={{ 'aria-label': 'Email address input' }}
                        variant='filled'
                        type='email'
                        placeholder='Enter your Email'
                        value={this.state.email}
                        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                        onChange={e => {
                          this.setState({ email: e.target.value });
                        }}
                        style={{ color: '#A2A2A2', flex: 1, maxWidth: '90%' }}
                        required
                      />
                      {this.state.emailPressed && !reportHelpers.validEmail(this.state.email) && (
                        <p style={{ color: 'red' }}>Please enter a valid email address.</p>
                      )}
                    </Grid>
                  </Grid>
                </StepComponent>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                    style={{ marginBottom: '15px' }}
                    className='covermore'
                  >
                    <MaterialCheckbox
                      covermore={true}
                      labelComponent={
                        <TermsLabel
                          togglePrivacy={() =>
                            this.setState(prevState => ({
                              isPrivacyVisible: !prevState.isPrivacyVisible,
                            }))
                          }
                          toggleTerms={() =>
                            this.setState(prevState => ({
                              isTermsVisible: !prevState.isTermsVisible,
                            }))
                          }
                        />
                      }
                      value={this.state.terms_accept}
                      onChange={() => {
                        this.setState(prevState => ({
                          terms_accept: !prevState.terms_accept,
                        }));
                      }}
                      inputProps={{
                        'aria-label': 'Accept Terms, Conditions and privacy policy',
                      }}
                    />
                  </Grid>
                  {/* <Grid container direction='row' justify='flex-start' alignItems='center'>
										<MaterialCheckbox
											covermore={true}
											labelComponent={
												<span
													style={{
														fontSize: '12px',
														wordBreak: 'wrap-word',
														maxWidth: '80%',
													}}
												>
													&nbsp;I'd like to be kept up to date by email with other exciting
													insurance offers and services from Cover-More Insurance
												</span>
											}
											value={this.state.further_information_accept}
											onChange={() => {
												this.setState(prevState => ({
													further_information_accept: !prevState.further_information_accept,
												}));
											}}
										/>
									</Grid> */}
                  {!this.state.terms_accept && this.state.emailPressed && (
                    <Grid container direction='row' justify='flex-start' alignItems='center'>
                      <p style={{ color: 'red' }}>Please accept our terms and conditions.</p>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <button
                    className='btn-swu'
                    onClick={() => {
                      this.setState({ emailPressed: true });
                      this.sendEmail();
                    }}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      color: 'white',
                      display: 'flex',
                    }}
                  >
										Email Report&nbsp;&nbsp;
                    <CovermoreArrow style={{ fill: 'white' }} />
                  </button>
                </Grid>
                <DocModal
                  isVisible={this.state.displayEmailSent}
                  onClose={() => this.setState({ displayEmailSent: false })}
                  hideBody
                  title='Your report will be emailed to you shortly.'
                />
                <DocModal
                  isVisible={this.state.isTermsVisible}
                  onClose={() => this.setState({ isTermsVisible: false })}
                  title='Terms and Conditions'
                  content={<TermsAndConditionsCV />}
                />
                <DocModal
                  isVisible={this.state.isPrivacyVisible}
                  onClose={() => this.setState({ isPrivacyVisible: false })}
                  title='Privacy Policy'
                  content={<PrivacyPolicyCV />}
                />
              </Grid>
            )}
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

const TermsLabel = ({ togglePrivacy, toggleTerms }) => (
  <React.Fragment>
    <span
      style={{
        fontSize: '12px',
        wordBreak: 'wrap-word',
        maxWidth: '80%',
      }}
    >
      <span style={{ color: 'var(--danger)' }}>*</span>I have read and understood the{' '}
      <button className='terms-button' style={styles.termsButton} onClick={togglePrivacy}>
        {' '}
				Privacy Policy
      </button>{' '}
			and{' '}
      <button className='terms-button' style={styles.termsButton} onClick={toggleTerms}>
        {' '}
				Terms of Use{' '}
      </button>{' '}
			carefully{' '}
    </span>
  </React.Fragment>
);
