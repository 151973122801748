import React from 'react';
import './MaterialCheckbox.scss';
const MaterialCheckbox = ({ value, labelComponent, covermore, ...restProps }) => (
  <React.Fragment>
    <label
      className={`pure-material-checkbox ${typeof covermore !== 'undefined' ? 'covermore' : ''}`}
      style={{ pointerEvents: 'none' }}
    >
      <input type='checkbox' checked={value} {...restProps} />
      <span className='label'>{labelComponent}</span>
    </label>
  </React.Fragment>
);

export default MaterialCheckbox;
