import React from 'react';
import '../PrivacyPolicy/privacy-policy.scss';

const styles = {
  center: {
    fontFamily: 'var(--cov-font)',
  },
  aTag: {
    color: 'var(--cov-blue)',
    fontWeight: '600',
  },
};

const PrivacyPolicyCV = () => (
  <React.Fragment>
    <div className='wwa-container' style={{ fontFamily: 'var(--cov-font)' }}>
      <h1 className='bold'>Privacy Policy</h1>
      <p>
        At DocHQ our mission is to put an accessible, flexible and affordable
        health service in the hands of everyone, on demand. We are passionate
        about high quality and convenient healthcare, whilst adhering to and
        maintaining your privacy at all times. We strive to comply with the
        General Data Protection Regulation (GDPR) and the Data Protection Act
        2018 (DPA).
      </p>
      <p>
        By continuing to use our Website and Services, you are deemed to agree
        to our Terms and Conditions and Privacy Policy for the collection and
        processing of your personal data.
      </p>
      <p>
        This Policy explains how we use your personal data, to help you
        understand how we work with your data, to allow you to make informed
        choices and be in control of your information. We urge you to spend a
        few moments understanding this Policy. Should this document be updated
        at any time, you will be informed accordingly.
      </p>
      <p>
        This Policy should be read in conjunction with our Terms & Conditions
        which can be found on our company website.
      </p>
      <p>
        For the purposes of the processing of your personal data, we are the
        Data Controller (as set out under EU General Data Protection Regulation
        2016 (GDPR). We are committed to protecting your privacy, both on and
        offline. We appreciate that you do not want the personal information you
        provide to us distributed indiscriminately and here we explain how we
        collect information, what we do with it and what controls you have over
        how we process it.
      </p>
      <p>This Privacy Policy is effective from 10th June 2020.</p>
      <p>
        Under GDPR we will ensure that your personal data is dealt with
        lawfully, fairly, and transparently, without adversely affecting your
        rights. We will only process your personal data if one or more of the
        following applies:
      </p>

      <ul className='wwa-desc wwa-list'>
        <li>
          You have given consent to the processing of your personal data for one
          or more specific purpose.
        </li>
        <li>
          Processing is necessary for the performance of a contract to which you
          are a party or in order for you to take action prior to entering into
          a contract.
        </li>
        <li>
          Processing is necessary for compliance with a legal obligation to
          which we are subject.
        </li>
        <li>
          Processing is necessary to protect the vital interests of you or of a
          third party.
        </li>
        <li>
          Processing is necessary for the performance of a task carried out in
          the public interest or in the exercise of official authority vested in
          the controller.
        </li>
        <li>
          Processing is necessary for the purposes of the legitimate interests
          pursued by us or by a third party, except where such interests are
          overridden by the fundamental rights and freedoms of the data subject
          which require protection of personal data.
        </li>
      </ul>
      <p>
        Unless otherwise specified, any processing of personal data undertaken
        by us, relating to providing services to a customer, a User of our
        system is processed based on our contractual relationship (or in
        anticipation of a contractual relationship).
      </p>
      <p>
        At no point will we share your confidential data with third parties not
        working in partnership with DocHQ.
      </p>

      <ol className='wwa-desc wwa-list '>
        <li>
          <span className='chapter-title'>
            Information we may collect from you:
          </span>
          <p>We may collect and process the following information about you:</p>
          <ul className='wwa-desc wwa-list'>
            <li>
              <strong>Medicine restrictions search information</strong> (i.e.
              travel details and email address) that you provide by completing
              the search form.
            </li>
            <li>
              <strong>IP address and URL </strong>your activity on our Website
              will be automatically recorded when you use or log on to any of
              the sites (including the site you exit to).
            </li>
            <li>
              <strong>Research and surveys</strong>, information from surveys
              that we may, from time to time, run for research purposes, should
              you choose to respond to them.
            </li>
            <li>
              <strong>When contacting us</strong>, communications you send to
              us, i.e. to report a problem or to submit queries or any comments
              regarding the website or its contents.
            </li>
            <li>
              <strong>Use of the service</strong>, details of your visits to the
              Website, the resources you access and details of any data you
              choose to download.
            </li>
          </ul>
          <p>You are under no obligation to provide any such information.
          However, if you choose to withhold requested information, this may
          result in us being unable to provide you with certain services.</p>
        </li>
        <li>
          <span className='chapter-title'>Uses made of your information:</span>
          <ul className='wwa-desc wwa-list'>
            <li>
              To enable us to provide you with the services and information
              offered
            </li>
            <li>To provide and administer access to our website.</li>
            <li>For confirmation of your identity.</li>
            <li>To respond to any communications received from you.</li>
            <li>
              To provide email, such as newsletters, alerts that you may have
              subscribed to. You can unsubscribe or opt-out of these at any
              given time by following the unsubscribe instructions.
            </li>
          </ul>
          <p>
            The use of your personal information will enable us to provide our
            services and to exercise our contract with you. In some cases, the
            collection of data may be a statutory or contractual requirement,
            and we will be limited in the services that we can provide you with
            without your consent to us to be able to use such data.
          </p>
          <p>
            We also collect, store and use the personal information listed above
            to:
          </p>
          <ul className='wwa-desc wwa-list'>
            <li>
              Monitor the downloading of data from the Platform to monitor and
              improve our services.
            </li>
            <li>
              Improve the layout and/or content of the pages of the Platform and
              to enable better customisation for Users.
            </li>
            <li>Identify visitors to the Platform.</li>
            <li>Carry out research on our User demographics.</li>
            <li>
              Tailor any information we may send to you based on the data that
              you submit to us.
            </li>
          </ul>
          <p>
            We have a legitimate interest in using your personal information for
            these purposes, so we can continue to grow and improve our services
            offered to our customers and to strictly ensure that we are only
            sending you information that is useful or relevant.
          </p>
          <p>In conclusion, we use your personal information to:</p>
          <ul className='wwa-desc wwa-list'>
            <li>
              Send you information you may find useful or which you have
              requested from us, including information about our products and
              services or those of carefully selected third parties such as
              information on relevant travel health care offered by third
              parties, provided you have provided prior full consent to be
              contacted for such purposes.
            </li>
            <li>
              With your consent, to share with carefully selected third parties
              (including Cover-more, please see{' '}
              <a
                style={styles.aTag}
                href='https://www.covermore.co.uk/privacy-policy'
              >
                https://www.covermore.co.uk/privacy-policy
              </a>
              ) to send you information directly which you may find useful
              regarding their travel products and services.
            </li>
          </ul>
          <p>
            You can tell us not to contact you with information regarding our
            travel products and services or those of third parties or to share
            your details with third parties so that they can send you
            information regarding their products and services at any given time.
            If you do not wish us to continue to use your information in this
            way you can follow the unsubscribe instructions on any
            communications sent to you.
          </p>
          <p>
            You can also exercise this right at any time by contacting us using
            the contact details at the end of this Privacy Policy.
          </p>
        </li>
        <li>
          <span className='chapter-title'>Information shared with others</span>
          <p>
            We will only share personal information (with your prior express
            approval), in certain limited circumstances as described below:
          </p>
          <p>
            Information that does not identify you: We may disclose aggregate
            statistics about visitors to the Platform, Users and sales to
            describe our services to prospective partners, investors,
            advertisers, sponsors and other reputable third parties and for
            other lawful purposes, but these statistics will include no
            personally identifiable information.
          </p>
          <p>
            We will not disclose, sell or rent any of your personal information
            to any third party unless you have directly consented to this first.
            If you do consent, but later change your mind, you may contact us,
            and we will cease any such activity. However, if in the event of
            undergoing a reorganisation or are sold to a third party, you agree
            that any personal information we hold about you may be transferred
            to that reorganised organisation or third party.
          </p>
          <p>
            We may also disclose your personal information if required to do so
            by law or if we believe that such action is necessary to prevent
            fraud or cyber-crime or the rights, property or personal safety of
            any persons.
          </p>
        </li>
        <li>
          <span className='chapter-title'>
            Our use and storage of information
          </span>
          <p>
            All information collected and supplied to DocHQ will be treated as
            strictly private and confidential and all data will be held in
            strict accordance with, and as long as required, under UK regulatory
            codes of practice on records management and data privacy laws.
          </p>
          <p>
            Storing your data: Records are retained in digital form in a secure
            and encrypted environment. We maintain our own internal Data
            Retention Policy, on which staff are trained and it is regularly
            reviewed to ensure total compliance with industry best practices.
          </p>
        </li>
        <li>
          <span className='chapter-title'>Additional information</span>
          <p>
            When you visit our Website, we may automatically collect additional
            information about you, ie. the type of internet browser you are
            using, the website from which you have come from to our Website and
            your IP address (the unique address which identifies your computer
            on the internet), which is automatically recognised by our server.
            You cannot be identified from this information and it is only used
            to assist and enable us in providing an efficient effective service
            on the Website.
          </p>
        </li>
        <li>
          <span className='chapter-title'>Cookies</span>
          <p>
            The Website uses cookies or similar technology to collect
            information about your access to the Website.
          </p>
          <p>
            Cookies are pieces of information which detail a unique reference
            code that a Website transfers to your device to store and sometimes
            track information about you. A number of cookies we use last only
            for the duration of your web session and expire when you close your
            browser. Other cookies are used to remember you when you return to
            the Website and will last longer.
          </p>
          <p className='bold'>We use cookies to:</p>
          <ul>
            <li>
              Identify that you have used our Website before, this enables us to
              ascertain the number of unique visitors we receive.
            </li>
            <li>Enable you to navigate the Website more quickly and easily.</li>
            <li>
              Remember your login so you can move from one page to another
              within the Website.
            </li>
            <li>Store your preferences.</li>
            <li>
              Customise parts of the layout and/or content of the pages of the
              Website for you.
            </li>
            <li>
              Gather statistical information about how you use the Website so
              that we can improve and evolve the service.
            </li>
          </ul>
          <p>
            Most computer and various mobile web browsers automatically accept
            cookies but, if you prefer, you can change your browser to prevent
            that or to notify you each time a cookie is set. You can also learn
            more about cookies by visiting www.allaboutcookies.org which
            includes additional useful information on cookies and how to block
            cookies using different types of browsers. Please note however, that
            by blocking or deleting cookies, it may not allow you to be able to
            take full advantage of our Website.
          </p>
          <p className='bold'>Our cookies will be used for:</p>
          <ul>
            <li>Crucial session management.</li>
            <li>
              Generating a specific log-in session for a visitor to the Website
              to allow the Website to remember that a visitor is logged in and
              that their page requests are delivered in an effective, secure and
              consistent way.
            </li>
            <li>
              Recognising when a visitor to our Website has visited before
              allowing us to identify the number of unique visitors we receive
              and to ensure we have enough capacity for the number of Users that
              receive.
            </li>
            <li>
              Verifying when a visitor to the Website is a registered member.
            </li>
            <li>
              We may also log information from your computer including the
              existence of cookies, your IP address and information about your
              browser program to allow us to diagnose problems, administer and
              monitor your usage of our services.
            </li>
          </ul>
          <p className='bold'>Functionality:</p>
          <ul>
            <li>
              Customising elements of the promotional layout and/or content of
              the pages of the Website for example by storing a country code and
              providing Users with content relevant to their own country.
            </li>
            <li>
              These crucial session management and functionality cookies are
              necessary to enable for to provide our service Platform.
            </li>
          </ul>
          <p className='bold'>Performance and measurement:</p>
          <ul>
            <li>
              Gathering statistical data about how our visitors use our Website
              in order for us to improve the Website and learn which parts are
              most favoured by our visitors.
            </li>
          </ul>
          <p>
            We have a genuine interest in using any personal information
            collected through performance and measurement cookies, this means
            that we can constantly monitor and consistently improve our Platform
            and our services available to you.
          </p>
        </li>
        <li>
          <span className='chapter-title'>Security</span>
          <p>
            We treat any personal and identifiable information that is
            associated with our Users with high importance. We have security
            measures in place to protect against the loss, misuse and alteration
            of personal information under our control. Our security and privacy
            policies are periodically reviewed and enhanced as necessary and
            only authorised personnel have access to personal information.
            Whilst we cannot ensure or guarantee that loss, misuse or alteration
            of information will never occur, we use all reasonable efforts to
            prevent it from occurring. Please bear in mind that the submission
            of any information over the internet is never entirely secure.
            Whilst we take appropriate technical and organisational measures to
            safeguard the personal information you provide, we are unable to
            guarantee the security of information you submit via the website
            whilst it is in transit over the internet and any such submissions
            are entirely at your own risk. In order to protect your personal
            information and to stop others from accessing it, it is of great
            importance that at the end of each session your browser is closed.
            This is of great importance if you are using a shared computer or a
            computer in a public place.
          </p>
        </li>
        <li>
          <span className='chapter-title'>Storage of your information</span>
          <p>
            Any Information that you submit is stored on secure servers located
            in the EU This is necessary in order to process the information in
            accordance with this Policy and our internal Privacy Policy.
          </p>
        </li>
        <li>
          <span className='chapter-title'>Your rights</span>
          <p>
            You have a legal right to access the personal information we hold
            about you at any time.
            <br />
            You also have a right to ask us to update and amend any out-of-date
            information or errors in said information free of charge, object to
            our use of your personal information for certain purposes, delete
            your personal information and transfer to you, where technically
            possible to another organisation a copy of the personal information
            about you that has been provided to us. <br />
            We process your personal data on the basis of your personal consent,
            and you may withdraw your consent for DocHQ to use your personal
            data as set above at any time by contacting us using the details
            below, and subject to the regulatory and legal requirements for
            DocHQ. You can withdraw your consent to our use of any of your
            personal information at any time, including any data sharing, using
            the settings in your DocHQ summary or by calling +44 (0)3300 880645
            or emailing contactus@dochq.co.uk.
            <br />
            You also have the right to submit any complaints at any time about
            our treatment of your personal information with a relevant
            supervisory authority, including, the Information Commissioner's
            Office in the UK.
            <br />
            Ways in which to contact the ICO's office can be found at
            <a style={styles.aTag} href='https://ico.org.uk/global/contact-us/'>
              {' '}
              https://ico.org.uk/global/contact-us/
            </a>
            <br />
          </p>

          <p className='bold'>Automated Decision-Making and Profiling</p>
          <p>
            In the event of DocHQ using personal data for the purposes of
            automated decision making and those decisions have a legal, or
            similarly significant effect to you, you have the right to challenge
            to such decisions under GDPR, requesting human intervention,
            expressing their own point of view, and obtaining a full explanation
            of the decision from DocHQ.
          </p>
          <p className='bold'>
            The above right does not apply in the following circumstances:
          </p>
          <ul>
            <li>
              The decision is necessary for the performance of and entry into a
              contract between DocHQ and you the User.
            </li>
            <li>The decision is authorised by law.</li>
            <li>You have given your explicit consent.</li>
          </ul>
          <p className='bold'>
            Where DocHQ uses your personal data for profiling purposes, the
            following will apply:
          </p>
          <ul>
            <li>
              Clear information explaining the profiling will be provided,
              including its significance and any likely consequences.
            </li>
            <li>Appropriate statistical procedures will be used.</li>
            <li>
              Technical and organisational measures necessary to minimise the
              risk of errors and to enable such errors to be easily rectified
              shall be implemented.
            </li>
          </ul>
          <p>
            All personal data processed for profiling purposes shall be secured
            in order to prevent any discriminatory effects stemming from the use
            of profiling.
          </p>
        </li>
        <li>
          <span className='chapter-title'>Contacting us </span>
          <p>
            Please submit any questions, concerns or comments you have regarding
            this Privacy Policy, or any requests concerning your personal
            information by email to contactus@dochq.co.uk, by telephone on +44
            (0)330 088 880645 between the hours of 09.00 am to 5:00 pm (UK
            time), Monday to Friday or write to us at:{' '}
            <span className='bold'>
              DocHQ, Wessex House, Upper Market Street, Eastleigh, Hampshire,
              SO50 9FD
            </span>
          </p>
        </li>
      </ol>
    </div>
  </React.Fragment>
);

export default PrivacyPolicyCV;
