import React from 'react';
import { Grid } from '@material-ui/core';

const StepComponent = props => {
  // split the message to remove first word, put that in strong tags
  const firstWord = props.stepMessage.substring(
    0,
    props.stepMessage.indexOf(' ')
  );
  const rest = props.stepMessage.replace(firstWord, '');
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Grid
        container
        direction='row'
        alignItems="center"
        style={{
          padding: '20px 0px',
        }}
      >
        <div className='arrow'>{props.stepNumber}</div>
        <div className='search-title'>
          <strong>{firstWord}</strong>
          {rest}
        </div>
      </Grid>
      <Grid container direction='column' justify='center' alignItems='stretch'>
        {props.children}
      </Grid>
    </Grid>
  );
};
export default StepComponent;
